const mutations = {
    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu

    TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
        state.isVerticalNavMenuActive = value
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val
    },
    UPDATE_MAIN_LAYOUT_TYPE(state, val) {
        state.mainLayoutType = val
    },
    UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
        state.verticalNavMenuItemsMin = val
    },
    UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
        state.verticalNavMenuWidth = width
    },

    // VxAutoSuggest

    UPDATE_STARRED_PAGE(state, payload) {
        // find item index in search list state
        const index = state.navbarSearchAndPinList.data.findIndex((item) => item.index == payload.index)

        // update the main list
        state.navbarSearchAndPinList.data[index].highlightAction = payload.val

        // if val is true add it to starred else remove
        if (payload.val) {
            state.starredPages.push(state.navbarSearchAndPinList.data[index])
        } else {
            // find item index from starred pages
            const index = state.starredPages.findIndex((item) => item.index == payload.index)

            // remove item using index
            state.starredPages.splice(index, 1)
        }
    },

    // Navbar-Vertical

    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
        const starredPagesMore = state.starredPages.slice(10)
        state.starredPages = list.concat(starredPagesMore)
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
        let downToUp = false
        let lastItemInStarredLimited = state.starredPages[10]
        const starredPagesLimited = state.starredPages.slice(0, 10)
        state.starredPages = starredPagesLimited.concat(list)

        state.starredPages.slice(0, 10).map((i) => {
            if (list.indexOf(i) > -1) downToUp = true
        })

        if (!downToUp) {
            state.starredPages.splice(10, 0, lastItemInStarredLimited)
        }
    },

    // ////////////////////////////////////////////
    // UI
    // ////////////////////////////////////////////

    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val
    },
    UPDATE_THEME(state, val) {
        state.theme = val
    },
    UPDATE_WINDOW_WIDTH(state, width) {
        state.windowWidth = width
    },
    UPDATE_WINDOW_SCROLL_Y(state, val) {
        state.scrollY = val
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
        // Get Data localStorage
        let userInfo = JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

        for (const property of Object.keys(payload)) {
            if (payload[property] != null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.AppActiveUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }
        }
        // Store data in localStorage
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_MID: (state, mid) => {
        state.mid = mid
    },
    SET_TOMID: (state, to_mid) => {
        state.to_mid = to_mid
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_DEVICE: (state, device) => {
        state.device = device
    },
    SET_PERSON_SIGN: (state, payload) => {
        state.personsign = payload
    },
    // SET_DEVICE_TOKEN: (state, devicetoken) => {
    //   state.devicetoken = devicetoken
    // },
    REMOVE_MID: (state) => {
        state.mid = ''
    },
    SET_DOCTYPE: (state, doctortype) => {
        state.doctortype = doctortype
    },
    REMOVE_DOCTYPE: (state) => {
        state.doctortype = 1
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_PARTNER: (state, payload) => {
        state.partner = payload
    },
    UPDATE_DOCTOR_STATUS: (state, status) => {
        state.doctorStatus = status
    },
    SET_VIDEO_PATH(state, payload) {
        state.videopath = payload
    },
    TOGGLE_RIGHT_PANEL(state, payload) {
        state.showRightPanel = payload
    },
    CALLIN_MASK(state, payload) {
        state.callInMask = payload
    },
    SET_TODOLIST(state, payload) {
        state.todolist = payload
    },
    SET_MYTODOLIST(state, payload) {
        state.mytodolist = payload
    },
    SET_TOMETODOLIST_TOTAL(state, payload) {
        state.tometodolistTotal = payload
    },
    SET_MYTODOLIST_TOTAL(state, payload) {
        state.mytodolistTotal = payload
    },
    SET_TOMETODOLIST_PAGE(state, payload) {
        state.tometodolistPage = payload
    },
    SET_MYTODOLIST_PAGE(state, payload) {
        state.mytodolistPage = payload
    },
    SET_MYOPINION(state, payload) {
        state.myopinion = payload
    },
    SET_OPINION(state, payload) {
        state.opinion = payload
    },
    SET_MYOPINION_TOTAL(state, payload) {
        state.myopinionTotal = payload
    },
    SET_OPINION_TOTAL(state, payload) {
        state.opinionTotal = payload
    },
    SET_MYOPINION_PAGE(state, payload) {
        state.myOpinionPage = payload
    },
    SET_OPINION_PAGE(state, payload) {
        state.OpinionPage = payload
    },
    SET_MYOPINION_START_DATE_TIME(state, payload) {
        state.myopinion_start_date_time = payload
    },
    SET_MYOPINION_END_DATE_TIME(state, payload) {
        state.myopinion_end_date_time = payload
    },
    SET_OPINION_START_DATE_TIME(state, payload) {
        state.opinion_start_date_time = payload
    },
    SET_OPINION_END_DATE_TIME(state, payload) {
        state.opinion_end_date_time = payload
    },
    SET_MYLOG(state, payload) {
        state.log = payload
    },
    SET_MYLOG_TOTAL(state, payload) {
        state.logtotal = payload
    },
    SET_LOGSEARCH(state, payload) {
        state.logsearch = payload
    },
    SET_LOGSEARCHPAYLOAD(state, payload) {
        state.logsearchpayload = payload
    },
    SET_LOGPAGE(state, payload) {
        state.logpage = payload
    },
    SET_OPFILTER_FLAG(state, payload) {
        state.opFilterFlag = payload
    },
    SET_WORD_S(state, payload) {
        state.wordS = payload
    },
    SET_WORD_O(state, payload) {
        state.wordO = payload
    },
    SET_WORD_A(state, payload) {
        state.wordA = payload
    },
    SET_WORD_P(state, payload) {
        state.wordP = payload
    },
    SET_WORD_MEMOP(state, payload) {
        state.wordMemo = payload
    },
    SET_WORD_NOTE(state, payload) {
        state.wordNote = payload
    },
    SET_NOTES(state, payload) {
        state.notes = payload
    },
    SET_SERVICELIST(state, payload) {
        state.servicelist = payload
    },
    SET_WORDSPEECHLIST(state, payload) {
        state.wordWD = payload
    },
    SET_DIETLIST(state, payload) {
        state.wordDiet = payload
    },
    SET_PLANSLIST(state, payload) {
        state.planslist = payload
    },
    SET_OPINION_ACTIVE_COUNT(state, payload) {
        state.opinionActiveCount = payload
    },
    SET_OPINION_ACTIVE_COUNT_INTOTAL(state, payload) {
        state.opinionActiveCountInTotal = payload
    },
    // PLAY_SOUND(state, payload) {
    //   state.playCallInSound = payload
    // },
    SET_ALL_HOSPITAL(state, payload) {
        state.allHospital = payload
    },
    SET_REFER_HOSPITAL(state, payload) {
        state.referHospital = payload
    },
    SET_ROOM_ID(state, payload) {
        state.roomid = payload
    },
    SET_MR_ID(state, payload) {
        state.mrid = payload
    },
    SET_QUEUE_ID(state, payload) {
        state.qid = payload
    },
    SET_QUEUE_DETAIL_PMEMO(state, payload) {
        state.queuePmemo = payload
    },
    SET_VERIFY(state, payload) {
        state.verify = payload
    },
    SET_ACTIVE_CHAT_USER(state, payload) {
        // console.log('active chat user ', payload)
        state.activeChatUser = payload
        // console.log('state.activeChatUser ', state.activeChatUser)
    },
    PLAY_CALLIN_SOUND_STATUS(state, payload) {
        state.playCallInSoundStatus = payload
    },
    SET_ICD10(state, payload) {
        state.icd10all = payload
    },
    SET_SOUND(state, payload) {
        state.sound = payload
    },
    SET_MEMBER_TOTAL(state, payload) {
        state.memberTotal = payload
    },
    SET_MEMBER_TOTAL2(state, payload) {
        state.memberTotal2 = payload
    },
    SET_UNREAD_MSG(state, payload) {
        state.unreadmsg = payload
    },
    SET_QUEUE(state, payload) {
        state.queue = payload
    },
    SET_MEMO_S(state, payload) {
        state.memoS = payload
    },
    SET_MEMO_O(state, payload) {
        state.memoO = payload
    },
    SET_MEMO_P(state, payload) {
        state.memoP = payload
    },
    SET_MEMO_DIET(state, payload) {
        state.memoDiet = payload
    },
    SET_SELECT_MEMBER_DETAIL(state, payload) {
        state.selectMemberDetail = payload
    },
    SET_AGENT_RESERVATION(state, payload) {
        state.agentReservation = payload
    },
    SET_AGENT_RESERVATION_COUNT(state, payload) {
        state.agentReservationCount = payload
    },
    SET_OTHER_DAY_RESERVATION(state, payload) {
        state.otherDayReservation = payload
    },
    SET_OTHER_DAY_RESERVATION_COUNT(state, payload) {
        state.otherDayReservationCount = payload
    },
    SET_EMERGENCY_RESERVATION(state, payload) {
        state.emergencyReservation = payload
    },
    SET_EMERGENCY_RESERVATION_COUNT(state, payload) {
        state.emergencyReservationCount = payload
    },
    SET_SPECIAL_RESERVATION(state, payload) {
        state.specialReservation = payload
    },
    SET_SPECIAL_RESERVATION_COUNT(state, payload) {
        state.specialReservationCount = payload
    },
    SET_TODAY_QUEUE(state, payload) {
        state.todayqueue = payload
    },
    SET_QUEUE_TOTAL(state, payload) {
        state.queuetotal = payload
    },
    SET_QUEUE_NOFINISH(state, payload) {
        state.queuenotfinishtotal = payload
    },
    SET_AM_UNFINISH(state, payload) {
        state.resverationAmNum = payload
    },
    SET_PM_UNFINISH(state, payload) {
        state.resverationPmNum = payload
    },
    SET_NIGHT_UNFINISH(state, payload) {
        state.resverationNightNum = payload
    },
    SET_AGENT_UNFINISH(state, payload) {
        state.resverationAgentNum = payload
    },
    SET_RING_STATUS(state, payload) {
        state.ringStatus = payload
    },
    SET_CONTACTSALL(state, payload) {
        state.contactsall = payload
    },
    SET_PROFILE(state, payload) {
        state.profile = payload
    },
    SET_DIVISION(state, payload) {
        state.division = payload
    },
    SET_DIVISION_E(state, payload) {
        state.divisionStrE = payload
    },
    SET_BANK_IMAGE(state, payload) {
        state.bankImage = payload
    },
    SET_LICENSE_IMAGE(state, payload) {
        state.licenseImage = payload
    },
    SET_ICD10_SELECTED(state, payload) {
        state.selectedicd10 = payload
    },
    SET_SYNC_SELECTEDTAB(state, payload) {
        state.selectedSyncTab = payload
    },
    SET_CALLING(state, payload) {
        state.calling = payload
    },
    SET_OPINION_ALL_DATA(state, payload) {
        state.opinionAllData = payload
    },
    SET_OPINION_STATE(state, payload) {
        state.opinionState = payload
    },
    SET_OPINION_ID(state, payload) {
        state.opinionId = payload
    },
    SET_OPINION_TYPE(state, payload) {
        state.opinionType = payload
    },
    SET_OPINION_STATUS(state, payload) {
        state.opinionStatus = payload
    },
    SET_PERSON_PHOTO(state, payload) {
        state.personalphoto = payload
    },
    SET_PERSON_EYE(state, payload) {
        state.personaleye = payload
    },
    SET_PERSON_STI(state, payload) {
        state.personalsti = payload
    },
    SET_PERSON_DICOM(state, payload) {
        state.personaldicom = payload
    },
    SET_SERVICE_UNIT(state, payload) {
        state.serviceUnit = payload
    },
    SET_PARTNER_EMPLOYEES(state, payload) {
        state.allemployees = payload
    },
    SET_QUERY_CALLINFO_DATE(state, payload) {
        state.filterCallInfoDate = payload
    },
    UPDATE_DIVISION(state, payload) {
        if (payload.type == 1) {
            var title = '內科'
        } else if (payload.type == 2) {
            var title = '外科'
        } else if (payload.type == 3) {
            var title = '其他科別'
        } else if (payload.type == 4) {
            var title = '中醫'
        }
        var tmpdivision = []
        tmpdivision = state.division
        var tmprs = _.find(tmpdivision, function (o) {
            return o.title == title
        })
        if (tmprs) {
            tmprs.di_id = payload.data
        } else {
            if (state.division == undefined) {
                state.division = []
            }
            state.division.push({
                title: title,
                di_id: payload.data,
            })
        }
    },
    SET_EXPERIENCE(state, payload) {
        let newCategory = { value: '' }
        //組合可以寫入8個學經歷的array
        if (payload[0] || payload[1]) {
            if (!payload[0]) {
                payload[0] = { title: '學歷', subtitle: [{ value: '' }] }
            } else if (!payload[1]) {
                payload[1] = { title: '經歷', subtitle: [{ value: '' }] }
            }
            for (let i = payload[0].subtitle.length; i < 8; i++) {
                payload[0].subtitle.push(newCategory)
            }
            for (let i = payload[1].subtitle.length; i < 8; i++) {
                payload[1].subtitle.push(newCategory)
            }
        } else {
            payload = [
                { title: '學歷', subtitle: [{ value: '' }] },
                { title: '經歷', subtitle: [{ value: '' }] },
            ]
            for (let i = 1; i < 8; i++) {
                payload[0].subtitle.push(newCategory)
            }
            for (let i = 1; i < 8; i++) {
                payload[1].subtitle.push(newCategory)
            }
        }
        state.experience = payload
    },
    SET_SMS_TIMES(state, payload) {
        state.smsTime = payload
    },
    SET_PAUSE_SMALL_BTN(state, payload) {
        state.pauseSmallBtn = payload
    },
    SET_ALL_DOCTOR(state, payload) {
        state.alldoctors = payload
    },
    SET_ALL_VIPMEMBERS(state, payload) {
        state.allvipmembers = payload
    },
    SET_ALL_VIPMEMBERS2(state, payload) {
        state.allvipmembers2 = payload
    },
    SET_QUERY_VIP_MEMBERS(state, payload) {
        state.vipMemberSearchQuery = payload
    },
    SET_QUERY_CRM_MEMBERS(state, payload) {
        state.crmMemberSearchQuery = payload
    },
    SET_CONTACTS(state, payload) {
        state.contacts = payload
    },
    SET_PROXY(state, payload) {
        state.proxy = payload
    },
    SET_CALLINFO(state, payload) {
        state.callinfo = payload
    },
    FILTER_CALLINFO_BY_DATE(state, payload) {
        state.callinfofilterbydate = payload
    },
    SET_CALLINFO_FILTER(state, payload) {
        state.callinfofilter = payload
    },
    SET_CHAT_CONTACT(state, payload) {
        var result = Object.keys(payload).map(function (key) {
            return payload[key]
        })
        state.chatContacts = result
    },
    SET_CHAT_CONTACT_WEBSOCKET(state, payload) {
        state.chatContactswebsocket.push(payload)
    },
    SET_CHAT_CONTACT_DOCTOR(state, payload) {
        state.chatContactsDoctor = payload
    },
    SET_SELECTED_PATIENT_DATA(state, payload) {
        state.selectedPatient = payload
    },
    SET_SEARCH_CONTACT(state, payload) {
        state.chatSearchQuery = payload
    },
    SET_CHAT_SELECTED_TAB(state, payload) {
        state.chatSelectedTab = payload
    },
    SET_CHAT_DATA_OF_USER(state, payload) {
        state.chatDataOfUser = payload
    },
    SET_CHAT_DATA_OF_DOCTOR(state, payload) {
        state.chatDataOfDoctor = payload
    },
    SET_CHAT_DATA_OF_GROUP(state, payload) {
        state.chatDataOfGroup = payload
    },
    SET_UNREAD(state) {
        state.chatContacts[this.position].unread = 0
    },
    SET_ACTIVE_CHAT_USER_MID() {
        // var alluser = state.chatContacts
        // var user = _.find(alluser, {'uid': payload})
    },
    SET_ROOM(state, payload) {
        state.roomList = payload
    },
    SET_ROOM_MEMBERS(state, payload) {
        state.roommemberlist = payload
    },
    SET_SELECTED_ROOMID(state, payload) {
        state.selectedRoomId = payload
    },
    APPEND_CHAT_DATA(state, payload) {
        // console.log('APPEND_CHAT ', payload)
        // console.log('msg ', state.chatDataOfUser)
        if (state.chatSelectedTab == 0) {
            state.chatDataOfUser.msg.push({
                textContent: payload.msg.textContent,
                time: payload.msg.time,
                isSeen: payload.msg.isSeen,
                isSent: payload.msg.isSent,
            })
        } else if (state.chatSelectedTab == 1) {
            state.chatDataOfDoctor.msg.push({
                textContent: payload.msg.textContent,
                time: payload.msg.time,
                isSeen: payload.msg.isSeen,
                isSent: payload.msg.isSent,
            })
        } else if (state.chatSelectedTab == 2) {
            state.chatDataOfGroup.msg.push({
                textContent: payload.msg.textContent,
                time: payload.msg.time,
                isSeen: payload.msg.isSeen,
                isSent: payload.msg.isSent,
            })
        }
    },
    SET_SMS_ROOM(state, payload) {
        state.smsRoom = payload
    },
    SET_OPINION_UNFINISH_TOTAL(state, payload) {
        state.unFinishOpinion = payload
    },
    SET_OPINION_UNFINISH_TOTAL_INTOTAL(state, payload) {
        state.unFinishOpinionInTotal = payload
    },
    SET_ACTIVE_PATIENT_INFO(state, payload) {
        state.activePatientInfo = payload
    },
    SET_EMR_BASE_DATA(state, payload) {
        state.userEMRBaseData = payload
    },
    SET_EMR_PERSON_OTHER_DATA(state, payload) {
        state.userEMRPersonSickDataOther = payload
    },
    // SET_PERSON_OTHER_DISEASE(state,payload){
    //   state.userEMRPersonotherdiesaseother = payload

    // },
    SET_PARTNER_ENTERPRISE(state, payload) {
        state.partnerEnterprise = payload
    },
    SET_EMR_PERSON_DATA(state, payload) {
        state.userEMRPersonSickFullData = payload
    },
    SET_EMR_PARENTS_SICK_DATA(state, payload) {
        state.userEMRParentsSickFullData = payload
    },
    SET_EMR_PARENTS_CANCER_DATA(state, payload) {
        state.userEMRParentsCancerFullData = payload
    },
    SET_EMR_SENSITIVE_DATA(state, payload) {
        state.userEMRSensitiveMattersFullData = payload
    },
    SET_EMR_LISTTYPE_DATA(state, payload) {
        state.userEMRLifeTypeFullData = payload
    },
    SET_HISTORY_PERSON_SICK(state, payload) {
        state.emrPersonSickData = payload
    },
    SET_CALLINFO_SEARCH_QUERY(state, payload) {
        state.callInfoSearchQuery = payload
    },
    SET_MYSERVICE(state, payload) {
        // console.log('mm payload', payload)
        state.myService = payload
    },
    //以選取的護理師資料
    SET_NURSE(state, payload) {
        state.chosenMedicalNurse = payload
    },
    //以選取的醫師資料
    SET_DOCTOR(state, payload) {
        state.chosenMedicaldoctor = payload
    },
    //所有護理師資料
    SET_ALL_NURSE(state, payload) {
        state.myMedicalAllNurse = payload
    },
    SET_MYSERVICELANG(state, payload) {
        state.myServiceLang = payload
    },
    UPDATE_MYSERVICE(state, payload) {
        payload.forEach(function (v) {
            state.myFullService.push(v)
        })
    },
    SET_CHAT_CONTACT_GROUP(state, payload) {
        state.chatContactsGroup = payload
    },
    SET_DOCTOR_UNIT(state, payload) {
        state.doctorSelectUnit = payload
    },
    SET_PERSON_BASE_SIGN(state, payload) {
        state.personBaseSign = payload
    },
    SET_PERSON_SIGN_2(state, payload) {
        state.personSign2 = payload
    },
    SET_PERSON_SIGN_3(state, payload) {
        state.personSign3 = payload
    },
    SET_PERSON_SIGN_4(state, payload) {
        state.personSign4 = payload
    },
    SET_PERSON_SIGN_5(state, payload) {
        state.personSign5 = payload
    },
    SET_PERSON_SIGN_6(state, payload) {
        state.personSign6 = payload
    },
    SET_PERSON_SIGN_7(state, payload) {
        state.personSign7 = payload
    },
    SET_PERSON_SIGN_8(state, payload) {
        state.personSign8 = payload
    },
    SET_PERSON_SIGN_9(state, payload) {
        state.personSign9 = payload
    },
    SET_PERSON_SIGN_10(state, payload) {
        state.personSign10 = payload
    },
    SET_PERSON_SIGN_11(state, payload) {
        state.personSign11 = payload
    },
    SET_PERSON_SIGN_12(state, payload) {
        state.personSign12 = payload
    },
    SET_PERSON_SIGN_13(state, payload) {
        state.personSign13 = payload
    },
    SET_PERSON_SIGN_14(state, payload) {
        state.personSign14 = payload
    },
    SET_SYNC_TRIGGLE(state, payload) {
        state.syncTriggle = payload
    },
    SET_SYNC_STATUS(state, payload) {
        state.syncStatus = payload
    },
    SET_SYNC_SWITCH(state, payload) {
        state.syncSwitch = payload
    },
    SET_QUERY_MEMBERS_BY_AREA(state, payload) {
        state.queryPartnerEnterprise = payload
    },
    SET_UIA_STAFF(state, payload) {
        state.uiastaff = payload
    },
    SET_DOCTOR_ENABLE(state, payload) {
        state.doctorEnable = payload
    },
    SET_CURRENT_PID(state, payload) {
        state.currentPid = payload
    },
    SET_CALLER_NAME(state, payload) {
        state.callerName = payload
    },
    SET_CALLER_PARTNER_ENTERPRISE(state, payload) {
        state.callerPartnerEnterprise = payload
    },
    SET_ULTRA_SOUND(state, payload) {
        state.userUltraSound = payload
    },
    SET_ECTROCARDIOGRAM(state, payload) {
        state.ectrocarDiogram = payload
    },
    SOCKET_CONNECT(state) {
        state.isConnected = true
    },
    SOCKET_DISCONNECT(state) {
        state.isConnected = false
    },
    SOCKET_ID(state, payload) {
        state.socketID = payload
    },
    SOCKET_INIT(state, payload) {
        // console.log('init:', payload)
    },
    SET_DID(state, payload) {
        state.did = payload
    },
    SET_TODO_UNFINISH_TOTAL(state, payload) {
        state.todoutotal = payload
    },
    SET_MYTODO_UNFINISH_TOTAL(state, payload) {
        state.mytodoutotal = payload
    },
    SET_CALLINFO_TOTAL(state, payload) {
        state.callinfototal = payload
    },
    SET_MSG_UNFINISH_TOTAL(state, payload) {
        state.msgtotal = payload
    },
    SET_CHAT_FIRST_CLICK(state, payload) {
        state.chatFirstClick = payload
    },
    SET_NOTIFY_UNFINISH_TOTAL(state, payload) {
        state.notifytotal = payload
    },
    SET_SCHEDULE_UNFINISH_TOTAL(state, payload) {
        state.scheduletotal = payload
    },
    SET_CHAT_PATIENT_COUNT(state, payload) {
        state.chatPatientCount = payload
    },
    SET_CHAT_DOCTOR_COUNT(state, payload) {
        state.chatDoctorCount = payload
    },
    SET_CHAT_GROUP_COUNT(state, payload) {
        state.chatGroupCount = payload
    },
    SET_SOCKET_CHAT_MSG_AND_TIME(state, payload) {
        state.socketChatMsgAndTime = payload
    },
    SET_RELOAD_PAGE(state, payload) {
        state.reloadPageBtnShow = payload
    },
    SET_ALL_BIO_DATA_CODENAME(state, payload) {
        state.bioDataCodenamearray = payload
    },
    SET_ALL_BIO_DATA(state, payload) {
        state.bioData = payload
    },
    SET_TOTAL_GROUP_MEMBERS(state, payload) {
        state.totalGroup = payload
    },
    SET_SERVICE_NOTE(state, payload) {
        state.servicenotes = payload
    },
    SET_CRM_MEMBERS(state, payload) {
        state.crmmembers = payload
    },
    SET_TITLE(state, payload) {
        state.title = payload
    },
    SET_DEV(state, payload) {
        state.isDev = payload
    },
    SET_BLOCKS(state, payload) {
        state.blocks = payload
    },
    SET_RESERVATION__VIEW_MODE(state, payload) {
        state.reservationViewModeType = payload
    },
    SET_MEMBER_VIEW_MODE(state, payload) {
        state.memberViewModeType = payload
    },
    SET_RESERVATION_FILTER_INDEX(state, payload) {
        state.reservationfilterindex = payload
    },
    SET_EMRTABSETTING(state, payload) {
        state.emrTabSetting = payload
    },
    SET_EMRMEDICATION(state, payload) {
        state.emrMedication = payload
    },
    SET_RESERVATIONTABSETTING(state, payload) {
        state.reservationTabSetting = payload
    },
    SET_HOMESETTING(state, payload) {
        state.homeSetting = payload
    },
    SET_PATIENT_INROOM(state, payload) {
        state.inRoom = payload
    },
    SET_MCUDATA(state, payload) {
        state.mcudata = payload
    },
    SET_SHOW_SMALL_VIDEO(state, payload) {
        state.showSmallVideo = payload
    },
    // SET_LIFE_HEALTH_DATA(state, payload) {
    //     state.lifeHealthData = payload
    // },
    SET_MEDICAL_TITLE_LIST(state, payload) {
        state.medicalTitleList = payload
    },
    SET_APPT_UNDONE_TOTAL(state, payload) {
        state.apptUndoneTotal = payload
    },
    SET_AGENT_APPT_UNDONE_TOTAL(state, payload) {
        state.agentApptUndoneTotal = payload
    },
    SET_DIET_CHART_DATA(state, payload) {
        state.dietChartData = payload
    },
    SET_CRM_OPEN_TOTAL(state, payload) {
        state.crmOpenTotal = payload
    },
    SET_CRM_CLOSE_TOTAL(state, payload) {
        state.crmCloseTotal = payload
    },
    SET_SOCKET_RECEIVE_MSG(state, payload) {
        state.socketReceiveMsg = payload
    },
    SET_CRM_NEW_CASE(state, payload) {
        state.crmNewCase = payload
    },
    SET_VIDEO_ACTIVE(state, payload) {
        state.videoActive = payload
    },
    SET_CALL_ID(state, payload) {
        state.callId = payload
    },
    SET_RING(state, payload) {
        state.ring = payload
    },
    SET_LOCAL_ID(state, payload) {
        state.localId = payload
    },
    SET_REMOTE_ID(state, payload) {
        state.remoteId = payload
    },
    SET_UUID(state, payload) {
        state.uuid = payload
    },
    SET_CHECKIN_QUEUE(state, payload) {
        state.checkInQueue = payload
    },
    SET_UNDONE_APPT_AM(state, payload) {
        state.undoneApptAm = payload
    },
    SET_UNDONE_APPT_PM(state, payload) {
        state.undoneApptPm = payload
    },
    SET_UNDONE_APPT_NIGHT(state, payload) {
        state.undoneApptNight = payload
    },
    SET_UNDONE_APPT_EMERGENCY(state, payload) {
        state.undoneApptEmergency = payload
    },
    SET_UNDONE_APPT_SPEC(state, payload) {
        state.undoneApptSpec = payload
    },
    SET_UNDONE_APPT_CS(state, payload) {
        state.undoneApptCs = payload
    },
    SET_UNDONE_APPT_TOTAL(state, payload) {
        state.undoneApptTotal = payload
    },
    SET_UNDONE_AGENT_APPT_AM(state, payload) {
        state.undoneAgentApptAm = payload
    },
    SET_UNDONE_AGENT_APPT_PM(state, payload) {
        state.undoneAgentApptPm = payload
    },
    SET_UNDONE_AGENT_APPT_NIGHT(state, payload) {
        state.undoneAgentApptNight = payload
    },
    SET_UNDONE_AGENT_APPT_EMERGENCY(state, payload) {
        state.undoneAgentApptEmergency = payload
    },
    SET_UNDONE_AGENT_APPT_SPEC(state, payload) {
        state.undoneAgentApptSpec = payload
    },
    SET_UNDONE_AGENT_APPT_CS(state, payload) {
        state.undoneAgentApptCs = payload
    },
    SET_UNDONE_AGENT_APPT_TOTAL(state, payload) {
        state.undoneAgentApptTotal = payload
    },
}
export default mutations
