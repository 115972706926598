import Vue from 'vue'
import App from './App.vue'
import VCharts from 'v-charts'
import _ from 'lodash'
import moment from 'moment'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
import 'vue-select/dist/vue-select.css'
Vue.use(Vuesax)

import device from 'vue-device-detector'
Vue.use(device)

import VueTelInput from 'vue-tel-input-fix'
Vue.use(VueTelInput)

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
//download reservationtable pdf
import htmlToPdf from '@/utils/htmlToPdf.js'
Vue.prototype['$getPdf'] = htmlToPdf.getPdf

import '@/permission'

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'
// console.log("router",router)
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value),
})

// Vuex Store
import store from './store/store'
// i18n
import i18n from './i18n/i18n'
// common
import common from '@/utils/common.js'
Vue.prototype.$common = common
// EventBus
Vue.prototype.$bus = new Vue()

// VeeValidate
import VeeValidate from 'vee-validate'
import en from 'vee-validate/dist/locale/en'
import tw from 'vee-validate/dist/locale/zh_TW'
import cn from 'vee-validate/dist/locale/zh_CN'

import VueClipboard from 'vue-clipboard2'

import VueGtag from 'vue-gtag'

import VueHtml2Canvas from 'vue-html2canvas'
import { getPatientInfoByPid } from '@/api/user'
Vue.use(VueHtml2Canvas)

Vue.use(
    VueGtag,
    {
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTIC },
    },
    router
)
console.log('VueGtag', process.env.VUE_APP_GOOGLE_ANALYTIC)
// import vueKanban from 'vue-kanban'
// import VueI18n from 'vue-i18n';
// Vue.use(VueI18n);

// const i18n = new VueI18n({
//   locale: 'zhTW',
// });
const config = {
    i18n,
    errorBagName: 'errorBags', // change if property conflicts.
    fieldsBagName: 'fieldBags',
    // i18n,
    dictionary: {
        en,
        tw,
        cn,
    },
}

Vue.use(VeeValidate, config)
Vue.use(VCharts)
// Vue.use(Vuetify)

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)
Vue.prototype.$moment = moment
Vue.use(VueClipboard)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import socket from './socket'
// import VueSocketIOExt from 'vue-socket.io-extended'
// import io from 'socket.io-client'

// const socket = io(process.env.VUE_APP_API_URL + '/staff', {
//     withCredentials: true,
//     transports: ['websocket'],
//     upgrade: false,
// })
// Vue.use(VueSocketIOExt, socket)

// Feather font icon
require('./assets/css/iconfont.css')
import { getToken, setToken } from '@/utils/auth'
import VueCountdown from '@chenfengyuan/vue-countdown'
Vue.component(VueCountdown.name, VueCountdown)
Vue.config.productionTip = false
Vue.prototype.baseURL = process.env.BASE_URL
Vue.use(require('vue-moment'))
// Vue.use(vueKanban)

//jsonToExcel
import toExcel from '@/utils/jsonToExcel'
Vue.prototype.$toExcel = toExcel

import FingerprintJS from '@fingerprintjs/fingerprintjs'

// import { getQueues } from '@/api/user'

export var app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: (h) => h(App),
    created() {
        var _self = this
        if (localStorage.getItem('language') == 'en') {
            _self.$i18n.locale = 'en'
        } else if (localStorage.getItem('language') == 'zh_TW') {
            _self.$i18n.locale = 'tw'
        } else if (localStorage.getItem('language') == 'zh_CN') {
            _self.$i18n.locale = 'cn'
        }

        // 設定axios 攔截器
        // this.axios.interceptors.request.use((config) => {
        //   let token = config.headers.authorization
        //   let oldtoken = localStorage.getItem('di_tokenId')
        //   if (token!=oldtoken) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
        //       config.headers.Authorization = token;
        //       console.log('interceptors config=',config)
        //       if(token){
        //         localStorage.setItem('di_tokenId', token)
        //         setToken(token)
        //       }else{
        //         // this.$router.push('/pages/login')
        //         return Promise.reject()
        //       }
        //   }
        //   return config
        // }, error => {
        //   return Promise.reject(error)
        // })

        // 設定axios 攔截器
        let isRefreshing = false
        axios.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                const originalRequest = error.config
                console.log('di_tokenId', localStorage.getItem('di_tokenId'))
                console.log('error.response', error.response)
                console.log('status', error.response.status)
                console.log('message', error.response.data.message)
                console.log('msg', error.response.data.msg)
                if (this.isDev == true) {
                    this.$store.dispatch('notify', {
                        color: 'danger',
                        title: error.response.status,
                        text: 'check console',
                    })
                    if (error.response.data.message)
                        this.$store.dispatch('notify', {
                            color: 'danger',
                            title: error.response.data.message,
                        })
                    if (error.response.data.msg)
                        this.$store.dispatch('notify', {
                            color: 'danger',
                            title: error.response.data.msg,
                        })
                }
                if (error.response === 'undefined' || error.response === undefined) {
                    console.log('error undefined')
                } else if (error.response.data.status == 'ERROR_TOKEN') {
                    console.log('登出 - error token')
                    _self.$router.push('/pages/login').catch(() => {})
                }
                if (error.response.status === 401) {
                    console.log('isRefreshing', isRefreshing)
                    if (!isRefreshing) {
                        console.log('getNewToken')
                        isRefreshing = true
                        var rft = localStorage.getItem('refresh_token')
                        setTimeout(function () {
                            isRefreshing = false
                        }, 10000)
                        return _self.getNewToken(rft).then((res) => {
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('di_tokenId')
                            isRefreshing = false
                            originalRequest.headers.Authorization = 'Bearer ' + localStorage.getItem('di_tokenId')
                            return axios(originalRequest)
                        })
                    } else {
                        console.log('這裡會執行？')
                        //等待token替換後重新執行
                        let loop = setInterval(function () {
                            if (localStorage.getItem('di_tokenId') == undefined) clearInterval(loop)
                            if (!isRefreshing) {
                                clearInterval(loop)
                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('di_tokenId')
                                originalRequest.headers.Authorization = 'Bearer ' + localStorage.getItem('di_tokenId')
                                return axios(originalRequest)
                            }
                        }, 100)
                    }
                    console.log('di_tokenId 2', localStorage.getItem('di_tokenId'))
                }
            }
        )

        // 檢查 系統所需的LS參數
        console.log('main created')
        this.checkSystemLS()
    },
    mounted() {
        // 取網址帶的query for p2p
        this.getUrlQuery()
        this.observeP2PElement()
        // 收到來自p2p的socket
        p2p.addEventListener('messagereceived', function (e) {
            const rcvMessage = JSON.parse(e.message)
            console.log('rcvMessage from main.js', rcvMessage)
            if (rcvMessage.p2pCallData != undefined) {
                if (rcvMessage.p2pCallData.queue_id) {
                    axios.get(process.env.VUE_APP_API_URL_HIS + '/api/getPatientInfoByQid/' + rcvMessage.p2pCallData.queue_id).then((res) => {
                        // console.log("addEventListener pid" ,res.data)
                        let pid = res.data.data.pid
                        if (rcvMessage.p2pAction != 'CheckIn' && rcvMessage.p2pAction != 'Aye' && rcvMessage.p2pAction != 'CheckOut') {
                            localStorage.setItem('p2p_queueid', rcvMessage.p2pCallData.queue_id)
                            localStorage.setItem('p2p_tomid', window.APP_PAGE_ROOT.dmid)
                            window.APP_PAGE_ROOT.$router.push('/emr2/' + pid + '/' + rcvMessage.p2pCallData.queue_id)
                            app.observeP2PElement()
                            // console.log(process.env.VUE_APP_URL +'/emr2/' + pid + '/' + rcvMessage.p2pCallData.queue_id)
                        }
                    })
                }
            }
            if (rcvMessage.p2pAction != undefined) {
                switch (rcvMessage.p2pAction) {
                    case 'CheckIn':
                        // console.log('邀請報到');
                        //top2p
                        if (rcvMessage.p2pCallData.queue_id) {
                            axios.get(process.env.VUE_APP_API_URL_HIS + '/api/getPatientInfoByQid/' + rcvMessage.p2pCallData.queue_id).then((res) => {
                                let pmid = res.data.data.mid
                                let pname = res.data.data.name
                                if (rcvMessage.p2pCallData.checkinID == 'curdr_' + res.data.data.partner_id + '_' + pmid + '_0') {
                                    Vue.prototype.$vs.notify({
                                        color: 'danger',
                                        title: pname,
                                        time: 8000,
                                        text: app.getInroom(),
                                        position: 'top-center',
                                    })
                                    app.checkInQueue(rcvMessage.p2pCallData.queue_id)
                                } else {
                                    // careGiver
                                    app.getPatientInfoBypid(res.data.data.pid, pname)
                                }
                            })
                        }
                        break
                    case 'Aye':
                        // console.log("我在等候")
                        app.checkInQueue(rcvMessage.p2pCallData.queue_id)
                        break
                    case 'CheckOut':
                        app.deleteCheckInQueue(rcvMessage.p2pCallData.queue_id)
                        break
                }
            }
        })
    },
    computed: {
        isDev() {
            return this.$store.getters.isDev
        },
        calling() {
            return this.$store.getters.calling
        },
        partnerid() {
            return this.$store.getters.partner
        },
        unreadmsg() {
            return this.$store.getters.msgtotal
        },
        activeChatUser() {
            return this.$store.getters.activeChatUser
        },
        chats() {
            return this.$store.getters.chatDataOfUser
        },
        chatPatientCount() {
            return this.$store.getters.chatPatientCount
        },
        chatDoctorCount() {
            return this.$store.getters.chatDoctorCount
        },
        chatGroupCount() {
            return this.$store.getters.chatGroupCount
        },
        uiastaff() {
            return this.$store.getters.uiastaff
        },
        device() {
            return this.$store.getters.device
        },
        syncTriggle() {
            return this.$store.getters.syncTriggle
        },
        device_token() {
            return localStorage.getItem('doc_access_token')
        },
        pathName() {
            return window.document.location.pathname
        },
        did() {
            return this.$store.getters.did
        },
        mid() {
            return this.$store.state.mid
        },
        call_id() {
            return this.$store.state.callId
        },
        ring() {
            return this.$store.state.ring
        },
        local_id() {
            return this.$store.state.localId
        },
        remote_id() {
            return this.$store.state.remoteId
        },
        uuid() {
            return this.$store.state.uuid
        },
        roomid() {
            return this.$store.state.roomid
        },
        dname() {
            return this.$store.getters.name
        },
        dmid() {
            return this.$store.state.mid
        },
    },
    watch: {
        mid(n) {
            // 登入後 p2p註冊
            if (n && n > 0) {
                console.log('p2p註冊', 'curdr_' + this.partnerid + '_' + this.mid + '_1')
                p2p_register('curdr_' + this.partnerid + '_' + this.mid + '_1', this.dname)
                setTimeout(this.checkP2PUrl(), 1000)
            }
        },
        partnerid(n) {
            var _self = this
            if (n != 0) {
                // 取主選單所有通知數量
                if (!(_self.$router.history.pending.fullPath == '/pages/login' || _self.$router.history.pending.fullPath == '/pages/forgetpassword' || _self.$router.history.pending.fullPath == '/pages/register' || _self.$router.history.pending.fullPath == '/pages/error-404')) {
                    _self.setMainSidebarQuantityTips()
                }
            }
        },
        $route() {
            console.log('執行$route')
            // 檢查 系統所需的LS參數
            this.checkSystemLS()
            // 檢查 登入後 系統所需的LS參數
            if (localStorage.getItem('di_tokenId')) {
                this.checkSystemLSAfterLogin()
            }
            // this.getUrlQuery()
            // this.checkP2PUrl()
            // 檢查是否為p2p通話來的網址（只有Android）
            // this.checkAndroidP2PUrl()
        },
    },
    data() {
        return {
            subscribers: [],
            ss: null,
            isRegistered: false,
            isIOS: 0,
            listQuery: {
                currentday: this.$moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                currenttime: '',
            },
            pInfo: [],
        }
    },
    sockets: {
        connect() {
            const socketId = this.$socket.client.id
            this.$store.commit('SOCKET_ID', this.$socket.client.id)
            const userId = Math.floor(Math.random().toFixed(4) * 10000)
            const tokenid2 = localStorage.getItem('di_tokenId')
            console.log('socket connected!')
            console.log('$socket => ', this.$socket)
            console.log('di_tokenId', tokenid2)
            if (tokenid2 != '' && tokenid2 != null) {
                this.ss = this.$socket.client.emit('user_login', {
                    userId,
                    socketId,
                    token: tokenid2,
                })
            } else {
                console.log('From Socket!!!!!')
                // this.$router.push('/pages/login').catch(() => {})
            }
        },
        disconnect(message) {
            console.log('socket disconnect!!! ==> ', message)
            // if (this.$store.getters.isDev==false){
            //   window.APP_PAGE_ROOT.reload();
            // }
        },
        receive_message(msg) {
            console.log('main socket connected msg ', msg)
            this.$store.commit('SET_SOCKET_RECEIVE_MSG', msg)
            // console.log('doc_access_token ' ,localStorage.getItem('doc_access_token'))
            if (msg) {
                var device = this.$store.getters.device
                switch (msg.entityType) {
                    // 重複登入
                    case 'login':
                        if (localStorage.getItem('di_tokenId') != null && msg.data.device_token !== localStorage.getItem('doc_access_token')) {
                            console.log('登出 - 重複登入', 'msg.data.device_token = ' + msg.data.device_token, 'LS的doc_access_token = ' + localStorage.getItem('doc_access_token'))
                            localStorage.removeItem('di_tokenId')
                            this.$socket.client.close()
                            this.$vs.dialog({
                                type: 'confirm',
                                color: 'danger',
                                title: this.$t('popup.alert'),
                                text: this.$t('main.repeatLogin'),
                                acceptText: this.$t('popup.confirm'),
                                accept: this.directLogout,
                                cancel: this.directLogout,
                                buttonCancel: true,
                            })
                            return false
                        }
                        break
                    // 變更醫師狀態
                    case 'doctor_status':
                        if (localStorage.getItem('di_tokenId')) {
                            this.$store.dispatch('fetchContact')
                        }
                    // break;
                    // 醫療影像同步
                    case 'room_msg':
                        if (msg.data.type == 'event') {
                            console.log('收到' + msg.data.trigger)
                            this.$store.commit('SET_SYNC_TRIGGLE', msg.data.trigger)
                        }
                        break
                    // 電話打來
                    case 'phonecall':
                        console.log(msg.data)
                        if (msg.data.mrid == '') {
                            sound.stop()
                        } else {
                            this.$socket.client.emit('join_room', {
                                room: msg.data.roomNumber,
                            })
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('di_tokenId')
                            axios.get(process.env.VUE_APP_API_URL_HIS + '/api/getPatientInfoByQid/' + msg.data.queueID).then((res) => {
                                console.log('call res ', res)
                                if (res.data.status == 'OK') {
                                    var videopath = ''
                                    if (device == 3) {
                                        sound.play()
                                    }
                                    let domain = window.location.host
                                    let testparam = ''
                                    if (domain == 'his-dev.curdoctor.com.tw' || domain == '127.0.0.1:8080') {
                                        testparam = 'test=true&'
                                    }
                                    if (res.data.data.cn_mcu == 1) {
                                        // 中國版
                                        if (res.data.data.source_ip == 'bj') {
                                            videopath =
                                                process.env.VUE_APP_BJ_MCU_CN + ':3004/?f=false&v=' + msg.data.videoCall + '&' + testparam + 'scheme_time=' + msg.data.scheme_time + '&room=' + msg.data.roomNumber + '&queue_id=' + msg.data.queueID + '&token=' + localStorage.getItem('doc_access_token')
                                        } else {
                                            videopath =
                                                process.env.VUE_APP_MCU_CN + ':3004/?f=false&v=' + msg.data.videoCall + '&' + testparam + 'scheme_time=' + msg.data.scheme_time + '&room=' + msg.data.roomNumber + '&queue_id=' + msg.data.queueID + '&token=' + localStorage.getItem('doc_access_token')
                                        }
                                    } else {
                                        videopath = process.env.VUE_APP_MCU + ':3004/?f=true&v=' + msg.data.videoCall + '&' + testparam + 'scheme_time=' + msg.data.scheme_time + '&room=' + msg.data.roomNumber + '&queue_id=' + msg.data.queueID + '&token=' + localStorage.getItem('doc_access_token')
                                    }
                                    this.$store.commit('SET_CALLING', true)
                                    this.$store.commit('CALLIN_MASK', true)
                                    this.$store.commit('SET_ACTIVE_PATIENT_INFO', {
                                        name: res.data.data.name,
                                        pid: res.data.data.pid,
                                    })
                                    this.$store.commit('SET_SELECT_MEMBER_DETAIL', res.data.data)
                                    this.$store.commit('SET_CALLER_NAME', msg.data.caller)
                                    this.$store.commit('SET_CALLER_PARTNER_ENTERPRISE', {
                                        partner_name: msg.data.partner_name,
                                        enterprise_name: msg.data.enterprise_name,
                                    })
                                    this.$store.dispatch('toggleRightPanel', true)
                                    this.$store.dispatch('setVideoPath', videopath)
                                    this.$store.dispatch('setRoomId', msg.data.roomNumber)
                                    this.$store.dispatch('setMR', {
                                        mrid: msg.data.mrid,
                                        qid: msg.data.queueID,
                                    })
                                    this.$store.commit('SET_QUEUE_ID', msg.data.queueID)
                                    // this.$store.commit('SET_SEND_MSG', true)
                                    // localStorage.setItem('selectedpid', JSON.stringify({'name': res.data.data.name, 'pid': res.data.data.pid}))
                                    var pid = res.data.data.pid
                                    if (this.$router.currentRoute.fullPath != '/emr2/' + pid + '/' + msg.data.queueID) {
                                        this.$router.push('/emr2/' + pid + '/' + msg.data.queueID)
                                    }
                                } else {
                                    this.$vs.notify({
                                        color: 'danger',
                                        title: this.$t('main.connectionFailed'),
                                        position: 'top-center',
                                    })
                                }
                            })
                        }
                        break
                    // 待辦事項 + 交辦事項 + 被邀請會診項目 + 邀請會診項目 + 班表變更 + 推版刷新 + 重複登入 + 聊天室
                    case 'msg':
                        // 變數處理
                        var fromid
                        var uname
                        var mdata
                        var mymsg
                        var mytype
                        var mdid
                        if (Array.isArray(msg.data)) {
                            fromid = msg.data[0].from_mid
                            mdata = msg.data[0]
                            mymsg = msg.data[0].msg_txt
                            mytype = msg.data[0].type
                            mdid = msg.data[0].msg_did
                            if (msg.data[0].type != 'group') {
                                uname = msg.data[0].name
                            }
                        } else {
                            fromid = msg.data.from_mid
                            mdata = msg.data
                            mymsg = msg.data.msg_txt
                            mytype = msg.data.type
                            mdid = msg.data.msg_did
                            if (msg.data.type != 'group') {
                                uname = msg.data.name
                            }
                        }
                        // 收到 訊息管理>通知
                        if (mymsg == 'Notify send') {
                            this.$store.dispatch('getCountByUnreadNotify')
                            // this.$store.dispatch('fetchQueue', this.listQuery)
                            // this.$store.dispatch('fetchAgentReservation', {
                            //     partnerid: this.partnerid,
                            // })
                            // this.$store.dispatch('fetchOtherDayReservation', {
                            //     partnerid: this.partnerid,
                            // })
                            // this.$store.dispatch('fetchSpecialReservation', {
                            //     partnerid: this.partnerid,
                            // })
                            return false
                        }
                        // 收到 待辦事項
                        if (mymsg == 'TODO send') {
                            this.getTodoList(1)
                            this.notification(this.$t('main.recieveNewMyTodoList'), this.$t('main.recieveNewMyTodoList'), '/images/chat.jpg')
                            this.$vs.notify({
                                color: 'grey',
                                title: this.$t('main.recieveNewMyTodoList'),
                                position: 'top-center',
                            })
                            return false
                        }
                        // 收到 交辦事項完成
                        if (mymsg == 'MyTODO send') {
                            this.getMyTodoList(1)
                            this.notification(this.$t('main.todoListDone'), this.$t('main.todoListDone'), '/images/chat.jpg')
                            this.$vs.notify({
                                color: 'grey',
                                title: this.$t('main.todoListDone'),
                                position: 'top-center',
                            })
                            return false
                        }
                        // 收到 被邀請會診項目
                        if (mymsg == 'OPINION send') {
                            this.getOpinions(1)
                            return false
                        }
                        // 收到 邀請會診項目完成
                        if (mymsg == 'MyOPINION send') {
                            this.getMyOpinions(1)
                            return false
                        }
                        // 收到 班表變更 通知
                        if (mymsg == 'MyShift send') {
                            this.notification(this.$t('main.shiftChangeNotice'), this.$t('main.shiftChangeNotice'), '/images/chat.jpg')
                            this.$vs.notify({
                                color: 'grey',
                                title: this.$t('main.shiftChangeNotice'),
                                position: 'top-center',
                            })
                            return false
                        }
                        // 程式推上去後，上面NavBar出現頁面更新的按鈕 set refresh BTN SHOW when update system
                        if (mytype == 'refresh') {
                            this.$store.commit('SET_RELOAD_PAGE', true)
                            this.$vs.loading.close()
                            return false
                        }
                        // 這下面都是聊天室
                        // 聊天室 - 通知
                        this.notification(this.$t('SendNotify'), mymsg, '/images/chat.jpg')
                        this.$vs.notify({
                            color: 'danger',
                            title: this.$t('SendNotify'),
                            text: this.$t('Someonesendsamessage'),
                            position: 'top-center',
                        })
                        // 聊天室 - 通知數量
                        this.$store.commit('SET_MSG_UNFINISH_TOTAL', this.unreadmsg + 1)
                        if (mdata.msg_group_id == 0) {
                            if (mdata.to_mid_type == 1) {
                                this.$store.commit('SET_CHAT_DOCTOR_COUNT', this.chatDoctorCount + 1)
                            } else {
                                this.$store.commit('SET_CHAT_PATIENT_COUNT', this.chatPatientCount + 1)
                            }
                        } else if (mdata.msg_group_id != 0) {
                            this.$store.commit('SET_CHAT_GROUP_COUNT', this.chatGroupCount + 1)
                        }
                        // 聊天室
                        if (this.$route.name == 'Chat') {
                            // 民眾
                            if (this.$store.getters.chatSelectedTab == 0) {
                                // 正在看的聊天室
                                if (mdata.from_mid == this.activeChatUser.usermid) {
                                    const newData = {
                                        textContent: mdata.msg_txt,
                                        time: mdata.date_time,
                                    }
                                    this.$store.commit('SET_SOCKET_CHAT_MSG_AND_TIME', newData)
                                    this.$store.commit('SET_CHAT_CONTACT_WEBSOCKET', { page: 1, nodataflag: 0 })
                                }
                                this.$store.dispatch('fetchChatContacts').then((res) => {
                                    var activePosition = this.activeChatUser.position
                                    var tmpchatContacts = _.cloneDeep(res)
                                    _.forEach(tmpchatContacts, function (v, i) {
                                        if (i == activePosition) {
                                            v.selected = true
                                        } else {
                                            v.selected = false
                                        }
                                    })
                                    this.$store.commit('SET_CHAT_FIRST_CLICK', true)
                                    this.$store.commit('SET_CHAT_CONTACT', tmpchatContacts)
                                })
                            }
                            // 醫事人員
                            else if (this.$store.getters.chatSelectedTab == 1) {
                                this.$store.dispatch('fetchChatContactsDoctor').then(() => {
                                    // 正在看的聊天室
                                    if (mdata.from_mid == this.activeChatUser.usermid) {
                                        this.$root.$emit('updateContact', this.activeChatUser)
                                    }
                                })
                            }
                            // 群組
                            else if (this.$store.getters.chatSelectedTab == 2) {
                                this.$store.dispatch('fetchChatContactsGroup').then(() => {
                                    // 正在看的聊天室
                                    if (mdata.msg_group_id == this.activeChatUser.usermid) {
                                        this.$root.$emit('updateContactGroup', this.activeChatUser)
                                    }
                                })
                            }
                        }
                        break
                    // 收到掛號通知
                    case 'queue':
                        localStorage.removeItem('reservation_filter_index')
                        switch (msg.data.status) {
                            // 掛號通知
                            case 'new queue':
                                this.notification(this.$t('RegisteredNotice'))
                                this.$vs.notify({
                                    color: 'grey',
                                    title: this.$t('RegisteredNotice'),
                                    text: this.$t('RegisteredNotice'),
                                    position: 'top-center',
                                })
                                break
                            // 完成報到通知
                            case 'check in':
                                this.notification(this.$t('Notificationofcompletion'))
                                this.$vs.notify({
                                    color: 'grey',
                                    title: this.$t('Notificationofcompletion'),
                                    text: this.$t('SomeoneReports'),
                                    position: 'top-center',
                                })
                                break
                            // 取消報到通知
                            case 'check out':
                                this.notification(this.$t('Cancelregistrationnotice'))
                                this.$vs.notify({
                                    color: 'grey',
                                    title: this.$t('Cancelregistrationnotice'),
                                    text: this.$t('Someonecanceledtheregistration'),
                                    position: 'top-center',
                                })
                                break
                        }
                        break
                    // 完成付款通知
                    case 'orderQueue':
                        localStorage.removeItem('reservation_filter_index')
                        this.notification(this.$t('PayDoneNotice'))
                        this.$vs.notify({
                            color: 'danger',
                            title: this.$t('PayDoneNotice'),
                            text: this.$t('PayDoneNotice'),
                            position: 'top-center',
                        })
                        // this.$store.dispatch('fetchApptUndoneQuantity', this.partnerid)
                        this.$store.dispatch('getTodayQueuesUndoneQuantity')
                        break
                    // 取消掛號通知
                    case 'closeQueue':
                        localStorage.removeItem('reservation_filter_index')
                        this.$vs.notify({
                            color: 'danger',
                            title: this.$t('CancelRegisteredNotice'),
                            text: this.$t('CancelRegisteredNotice'),
                            position: 'top-center',
                        })
                        // this.$store.dispatch('fetchApptUndoneQuantity', this.partnerid)
                        this.$store.dispatch('getTodayQueuesUndoneQuantity')
                        break
                    // 病患進入房間
                    case 'join_room':
                        this.$vs.notify({
                            color: 'danger',
                            title: msg.data.p_name,
                            time: 8000,
                            text: '已進入診間',
                            position: 'top-center',
                        })
                        this.watchRoom(msg.data.p_mid, msg.data.queue_id)
                        break
                    // 房間建立
                    case 'create_room':
                        this.watchRoom(msg.data.p_mid, msg.data.queue_id)
                        break
                    // 系統通知 sysPushMessage
                    case 'sys_msg':
                        this.$store.dispatch('getCountByUnreadNotify')
                        this.$store.dispatch('gray', {
                            color: 'danger',
                            title: msg.data.title,
                            text: msg.data.text,
                        })
                        break
                    // 紅色通知
                    case 'notify':
                        this.$store.dispatch('getCountByUnreadNotify')
                        this.$store.dispatch('notify', {
                            color: 'danger',
                            title: msg.data,
                        })
                        break
                    // 收到crm案件
                    case 'crm_new_case':
                        this.$store.dispatch('fetchSRQuantity', {
                            partnerid: this.partnerid,
                        })
                        if (this.$router.currentRoute.fullPath == '/crm') this.$store.commit('SET_CRM_NEW_CASE', true)
                        break
                    // p2p 收到通話
                    case 'open_stream':
                        $('#p2p').show()
                        console.log('open_stream data = ', msg.data)
                        remoteID = msg.data.remoteID
                        console.log('open_stream remoteID => ', remoteID)
                        store.commit('SET_CALL_ID', msg.data.callID)
                        store.commit('SET_RING', msg.data.ring)
                        store.commit('SET_LOCAL_ID', msg.data.localID)
                        store.commit('SET_REMOTE_ID', msg.data.remoteID)
                        store.commit('SET_ROOM_ID', msg.data.roomID)
                        store.commit('SET_CALLING', true)
                        store.dispatch('setRoomId', msg.data.roomID)
                        this.$store.dispatch('updateDoctorStatus', 2000)
                        p2p_call(true, msg.data.roomID, msg.data.callID, msg.data.remoteID, true, msg.data.ring, false, this.UUID)
                        if (msg.data.queueID) {
                            axios.get(process.env.VUE_APP_API_URL_HIS + '/api/getPatientInfoByQid/' + msg.data.queueID).then((res) => {
                                var pid = res.data.data.pid
                                localStorage.setItem('p2p_queueid', msg.data.queueID)
                                localStorage.setItem('p2p_tomid', this.dmid)
                                this.$router.push('/emr2/' + pid + '/' + msg.data.queueID)
                            })
                        }
                        // store.commit('SET_VIDEO_ACTIVE',true)
                        // p2p_call(true, msg.data.callID, msg.data.remoteID, true, msg.data.ring, false, this.UUID);
                        break
                }
            }
        },
    },
    methods: {
        // 如果token過期，自動替換token
        getNewToken(refresh_token) {
            return axios
                .post(
                    process.env.VUE_APP_API_URL + '/app/restful_api/user/refresh',
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8',
                        },
                        data: {
                            refresh_token: localStorage.getItem('refresh_token'),
                        },
                    }
                )
                .then((response) => {
                    localStorage.removeItem('di_tokenId')
                    localStorage.removeItem('refresh_token')
                    let token = response.data
                    if (token.access_token != undefined) {
                        localStorage.setItem('di_tokenId', token.access_token)
                        localStorage.setItem('refresh_token', token.refresh_token)
                    }
                    return Promise.resolve(true)
                })
        },
        // 取網址帶來的query
        getUrlQuery() {
            if (!this.$route.query) return
            const url = window.location.href
            store.commit('SET_UUID', this.getQueryParam(url, 'uuid'))
            store.commit('SET_CALL_ID', this.getQueryParam(url, 'callID'))
            store.commit('SET_RING', this.getQueryParam(url, 'ring'))
            store.commit('SET_LOCAL_ID', this.getQueryParam(url, 'localID'))
            store.commit('SET_REMOTE_ID', this.getQueryParam(url, 'remoteID'))
            store.commit('SET_ROOM_ID', this.getQueryParam(url, 'roomID'))
            // console.log('收到通話', true, roomID, callID, remoteID, true, ring, UUID)
            // p2p_call(true, roomID, callID, remoteID, false, name, false,UUID);
        },
        // 取網址帶來的query
        getQueryParam(url, param) {
            const match = url.match(new RegExp(`[?&]${param}=([^&]+)`))
            return match ? decodeURIComponent(match[1]) : null
        },
        checkP2PUrl() {
            if (!this.roomid || !this.call_id || !this.ring || !this.remote_id) return
            console.log(this.roomid, this.call_id, this.ring, this.remote_id)
            // if (!this.call_id || !this.ring || !this.remote_id) return;
            store.commit('SET_CALL_ID', this.call_id)
            store.commit('SET_ROOM_ID', this.roomid)
            remoteID = this.remote_id
            console.log('checkP2PUrl remoteID => ', remoteID)
            let queue_id = localStorage.getItem('p2p_queueid')
            if (localStorage.getItem('doc_access_phoneType') == 1) {
                this.$store.dispatch('updateDoctorStatus', 2000)
                p2p_call(false, this.roomid, this.call_id, this.remote_id, false, this.ring, false, this.uuid)
                console.log('call to ios')
            } else {
                console.log('this.ring => ', this.ring)
                let ring = decodeURI(this.ring.replace(/\+/g, ' '))
                console.log('ring => ', ring)
                this.$store.dispatch('updateDoctorStatus', 2000)
                p2p_call(true, this.roomid, this.call_id, this.remote_id, true, ring, false, this.uuid)
                console.log('call to Android')
            }

            // const payload = {
            //     queue_id: queue_id,
            // }
            // getQueues(payload).then((res) => {
            //     console.log('getQueues', res.data.data)
            // })

            // axios.get(process.env.VUE_APP_API_URL_HIS + '/api/getPatientInfoByQid/' + queue_id).then((res) => {
            //     let pid = res.data.data.pid
            //     console.log('pid', res.data.data.pid)
            //     if (this.$router.currentRoute.fullPath != '/emr2/' + pid + '/' + queue_id) {
            //         this.$router.push('/emr2/' + pid + '/' + queue_id)
            //     }
            // })

            // store.commit('SET_ACTIVE_CALL_BAR', true)
            // this.$router.push('/cust/' + store.state.callBarCustParams[0].id)
        },
        // 登出
        directLogout() {
            this.$router.push('/pages/login')
            window.location.reload()
        },
        // 取 邀請會診項目
        getMyOpinions(page) {
            var _self = this
            _self.$vs.loading()
            _self.$store
                .dispatch('fetchMyOpinion', page)
                .then((res) => {
                    var items = res.data.items
                    var count = 0
                    items.forEach(function (v) {
                        if (v.status == 0) {
                            count += 1
                        }
                    })
                    this.$store.commit('SET_OPINION_ACTIVE_COUNT', count)
                })
                .catch((error) => {
                    _self.$vs.loading.close()
                    console.log(error)
                })
                .then(() => {
                    _self.notification(_self.$t('Consultationnotice'), _self.$t('Consultationnotice'), '/images/chat.jpg')
                    _self.$vs.notify({
                        color: 'danger',
                        title: _self.$t('Consultationnotice'),
                        text: _self.$t('Someonerequestsaconsultation'),
                        position: 'top-center',
                    })
                    _self.$vs.loading.close()
                })
        },
        // 取 被邀請會診項目
        getOpinions(page) {
            var _self = this
            _self.$vs.loading()
            _self.$store
                .dispatch('fetchOpinion', page)
                .then((res) => {
                    var items = res.data.items
                    var count = 0
                    items.forEach(function (v) {
                        if (v.status == 0) {
                            count += 1
                        }
                    })
                    this.$store.commit('SET_OPINION_UNFINISH_TOTAL', count)
                })
                .catch((error) => {
                    _self.$vs.loading.close()
                    console.log(error)
                })
                .then(() => {
                    _self.notification(_self.$t('Consultationnotice'), _self.$t('Consultationnotice'), '/images/chat.jpg')
                    _self.$vs.notify({
                        color: 'danger',
                        title: _self.$t('Consultationnotice'),
                        text: _self.$t('Someonerequestsaconsultation'),
                        position: 'top-center',
                    })
                    _self.$vs.loading.close()
                })
        },
        // 取 交辦事項
        getTodoList(page) {
            var _self = this
            _self.$vs.loading()
            _self.$store
                .dispatch('fetchToMeTodoList', page)
                .then((res) => {
                    _self.$store.commit('SET_TODO_UNFINISH_TOTAL', res.data.count)
                })
                .catch((error) => {
                    console.log(error)
                })
                .then(() => {
                    _self.$vs.loading.close()
                })
        },
        // 取 待辦事項
        getMyTodoList(page) {
            var _self = this
            _self.$vs.loading()
            _self.$store
                .dispatch('fetchMyTodoList', page)
                .then((res) => {
                    var items = res.data.items
                    var count = 0
                    items.forEach(function (v) {
                        if (v.status == 0) {
                            count += 1
                        }
                    })
                    _self.$store.commit('SET_MYTODO_UNFINISH_TOTAL', count)
                })
                .catch((error) => {
                    console.log(error)
                })
                .then(() => {
                    _self.$vs.loading.close()
                })
        },
        // 取 時段
        getinterval() {
            var tmptime = this.$moment(new Date(), 'H').format('H')
            let interval = 1
            if (tmptime > 9 && tmptime < 13) {
                interval = 1
            } else if (tmptime >= 13 && tmptime < 18) {
                interval = 2
            } else if (tmptime >= 18 && tmptime < 22) {
                interval = 3
            }
            return interval
        },
        // 電腦網頁版 右下角通知 顯示6秒
        notification(msg, body = '', icon = '') {
            if (this.device != 3) {
                return
            }
            let notifyConfig = {
                body: body, // 設定內容
                icon: icon, // 設定 icon
            }
            console.log(msg, notifyConfig)
            var n = new Notification(msg, notifyConfig)
            n.onshow = function () {
                setTimeout(n.close, 6000)
            }
        },
        // 取主選單所有通知數量
        setMainSidebarQuantityTips() {
            this.$store.dispatch('getMembersTotal', {
                partner_id: this.partnerid,
            })
            this.$store.dispatch('getCountByTodo')
            this.$store.dispatch('getCountByUnreadMsg')
            this.$store.dispatch('getCountByUnreadNotify')
            this.$store.dispatch('getCountByUnreadSchedule')
            this.$store.dispatch('fetchOpinion', 1)
            this.$store.dispatch('fetchMyOpinion', 1)
            // this.$store.dispatch('fetchApptUndoneQuantity', this.partnerid)
            this.$store.dispatch('getTodayQueuesUndoneQuantity')
            this.$store.dispatch('fetchSRQuantity', {
                partnerid: this.partnerid,
            })
        },
        // 判斷手機裝置是不是WebView
        isWebview() {
            if (this.isIOS == 1) {
                return true
            }
            if (localStorage.getItem('doc_access_phoneType') == 1) {
                return true
            }
            var useragent = navigator.userAgent
            var rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv|.0.0.0)']
            var regex = new RegExp(`(${rules.join('|')})`, 'ig')
            return Boolean(useragent.match(regex))
        },
        // 得知使用者唯一裝置token
        async get_user_device_token() {
            const fpPromise = await FingerprintJS.load()
            const fp = await fpPromise
            const result = await fp.get()
            // 得知裝置device_token
            const visitorId = result.visitorId
            console.log('device_token', visitorId)
            localStorage.setItem('doc_access_token', visitorId)
        },
        // 檢查 系統所需的LS參數
        checkSystemLS() {
            console.log('start check!')

            if (this.$device.windows === true || !this.isWebview()) {
                // device_token
                if (!localStorage.getItem('doc_access_token')) {
                    console.log('取device_token！！！')
                    this.get_user_device_token()
                }
                // phone type
                if (!localStorage.getItem('doc_access_phoneType')) {
                    console.log('取phone type！！！')
                    localStorage.setItem('doc_access_phoneType', 3)
                }
            } else {
                // 手機版 含device_token, apns_token, phonetype
                if (window.location.href.includes('voipToken') && !localStorage.getItem('doc_access_token')) {
                    this.getMobileDeviceToken()
                } else {
                    if (!localStorage.getItem('doc_access_token')) {
                        console.log('取device_token！！！ mobile')
                        this.get_user_device_token()
                    }
                    // phone type
                    if (!localStorage.getItem('doc_access_phoneType')) {
                        console.log('取phone type！！！ mobile')
                        localStorage.setItem('doc_access_phoneType', 3)
                    }
                }
            }

            this.$store.dispatch('setDevice', localStorage.getItem('doc_access_phoneType'))

            // LS result
            let result = {
                device_token: localStorage.getItem('doc_access_token'),
                apns_token: localStorage.getItem('doc_access_apns'),
                phone_type: localStorage.getItem('doc_access_phoneType'),
            }
            console.log('before login LS', result)
        },
        // 檢查 登入後 系統所需的LS參數
        checkSystemLSAfterLogin() {
            var _self = this
            console.log('(After Login) start check!')

            // refresh token
            // 寫在攔截器

            // partnerlist
            if (!localStorage.getItem('partnerlist')) {
                _self.$store.dispatch('getUIAPartner', _self.uiastaff).then((res) => {
                    this.getPartnerList(res.data.results)
                })
            }

            // his config
            if (!localStorage.getItem('his_config') || !localStorage.getItem('partner_info')) {
                _self.$store.dispatch('getConfig', _self.partnerid).then((res) => {
                    localStorage.setItem('his_config', JSON.stringify(res.data.data))
                    localStorage.setItem('partner_info', JSON.stringify(res.data.pi_data))
                    localStorage.setItem('is_p2p', res.data.pi_data.is_p2p)
                })
            }

            // membership.device_token
            var payload = {
                device_token: localStorage.getItem('doc_access_token'),
                type: localStorage.getItem('doc_access_phoneType'),
            }

            // device_token資料的防呆機制，並回傳device_token資料
            _self.$store.dispatch('checkDeviceToken', payload).then((res) => {
                if (res.data.status == 'OK') {
                    if (localStorage.getItem('doc_access_token') == res.data.data[0].token) {
                        console.log('msg', res.data.msg)
                        console.log('membership.device_token', res.data.data[0])
                    } else {
                        console.log('logout msg', 'login on another device')
                        _self.$store
                            .dispatch('logout')
                            .then(() => {
                                _self.$socket.client.emit('login_out')
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                            .then(() => {
                                _self.$socket.client.close()
                                window.location.reload()
                            })
                    }
                } else {
                    // error no db device_token!
                    console.log('logout msg', res.data.msg)
                    _self.$store
                        .dispatch('logout')
                        .then(() => {
                            _self.$socket.client.emit('login_out')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                        .then(() => {
                            _self.$socket.client.close()
                            window.location.reload()
                        })
                }
            })

            // LS result
            let result = {
                refresh_token: localStorage.getItem('refresh_token'),
                partnerlist: localStorage.getItem('partnerlist'),
                his_config: localStorage.getItem('his_config'),
            }
            console.log('after login LS', result)
        },
        // 視訊房間建立，監控房間
        watchRoom(p_mid, queue_id) {
            let route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')

            if (route == 'reservation') {
                var mcudata = _.cloneDeep(this.$store.state.mcudata)
                if (!_.find(mcudata, { mid: p_mid, qid: queue_id })) {
                    mcudata.push({ mid: p_mid, qid: queue_id })
                }
                this.$store.commit('SET_MCUDATA', mcudata)
            }
        },
        checkInQueue(queue_id) {
            let temp = _.cloneDeep(this.$store.state.checkInQueue)
            temp.push(parseInt(queue_id))
            temp = _.uniq(temp)
            this.$store.commit('SET_CHECKIN_QUEUE', temp)
        },
        deleteCheckInQueue(queue_id) {
            let temp = _.cloneDeep(this.$store.state.checkInQueue)
            temp = temp.filter((item) => item !== parseInt(queue_id))
            this.$store.commit('SET_CHECKIN_QUEUE', temp)
        },
        getPatientInfoBypid(pid, pname) {
            const payload = {
                spid: pid,
            }
            getPatientInfoByPid(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.pInfo = res.data.data
                    console.log(this.pInfo.careGiver)
                    Vue.prototype.$vs.notify({
                        color: 'danger',
                        title: this.$t('reservation.patient') + pname + this.$t('reservation.familyMembers'),
                        time: 8000,
                        text: this.pInfo.careGiver[0].name + this.$t('reservation.Inroom'),
                        position: 'top-center',
                    })
                }
            })
        },
        // 視窗通知
        notify(color, title, text) {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                time: 8000,
                position: 'top-center',
            })
        },
        // 手機版 含device_token, apns_token, phonetype
        getMobileDeviceToken() {
            console.log('url', window.location.href)
            let url = ''
            let vars = ''
            let getVars = {}
            let tmp = ''
            url = window.location.href.split('?')
            if (url[1].includes('&')) {
                vars = url[1].split('&')
            }
            if (vars != '') {
                vars.forEach(function (v) {
                    tmp = v.split('=')
                    if (tmp.length == 2) getVars[tmp[0]] = tmp[1]
                })
                console.log('apnsToken', getVars.apnsToken)
                localStorage.setItem('doc_access_apns', getVars.apnsToken)
                localStorage.setItem('doc_access_token', getVars.voipToken)
                localStorage.setItem('doc_access_phoneType', getVars.phoneType)
                this.isIOS = getVars.phoneType
            }
        },
        // 取 Partner List
        getPartnerList(list) {
            let arr = []
            list.forEach(function (v, i) {
                arr[i] = v.id
            })
            let payload = {
                partner_id_arr: arr,
            }
            fetchApptUndoneQuantity(payload).then((res) => {
                res.data.data.forEach(function (v) {
                    let index = _.findIndex(list, ['id', v.partner_id])
                    list[index].apptTotal = v.total
                })
                res.data.agentData.forEach(function (v) {
                    let index = _.findIndex(list, ['id', v.partner_id])
                    list[index].agentApptTotal = v.total
                })
            })
            let temp
            temp = _.find(list, ['id', this.partnerid])
            _.pull(list, temp)
            list = _.concat(temp, list)
            localStorage.setItem('partnerlist', JSON.stringify(list))
        },
        // 檢查是否為p2p通話來的網址（只有Android）
        checkAndroidP2PUrl() {
            console.log('call to Android')
            var _self = this
            if (this.device != 2) return
            if (!this.$route.query) return
            var data = this.$route.query
            if (!data.callID || !data.ring || !data.localID || !data.remoteID) return
            this.$store.commit('SET_CALL_ID', data.callID)
            this.$store.commit('SET_RING', data.ring)
            this.$store.commit('SET_LOCAL_ID', data.localID)
            this.$store.commit('SET_REMOTE_ID', data.remoteID)
            p2p_call(true, this.roomid, this.callId, this.remoteId, true, data.ring, false, this.uuid)
            // var loop = setInterval(function(){
            //   _self.$store.commit('SET_VIDEO_ACTIVE',true)
            //   clearInterval(loop);
            // }, 1000)
        },
        // 觀察是否在通話中(是否顯示視訊窗格)
        observeP2PElement() {
            const el = $('#p2p')[0]
            const option = {
                threshold: [0.5],
            }
            const callback = (entries, observe) => {
                if (entries[0].isIntersecting) {
                    this.$store.commit('SET_CALLING', true)
                } else {
                    this.$store.commit('SET_CALLING', false)
                }
            }
            const observer = new IntersectionObserver(callback, option)
            observer.observe(el)
        },
        getInroom() {
            return this.$t('reservation.Inroom')
        },
    },
    beforeRouteLeave(to, from, next) {
        console.log('leave main')
        localStorage.removeItem('refreshNewTime')
        next()
    },
})
