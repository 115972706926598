import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'
import Vue from 'vue'
import store from './store/store'

const socket = io(process.env.VUE_APP_API_URL + '/staff', {
    withCredentials: true,
    transports: ['websocket'],
    upgrade: false,
})

Vue.use(VueSocketIOExt, socket, {
    store, // 傳入 Vuex store
    actionPrefix: 'SOCKET_', // Vuex action 的前綴
    mutationPrefix: 'SOCKET_', // Vuex mutation 的前綴
})
