export default {
    // 這裡的命名符合 Vue-Socket.IO-Extended 的規範
    SOCKET_RECEIVE_MESSAGE(state, msg) {
        state.message = msg
    },
    SOCKET_CONNECT(state) {
        state.isConnected = true
    },
    SOCKET_DISCONNECT(state) {
        state.isConnected = false
    },
}
